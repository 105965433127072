<template>
  <div class="app-container">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="姓名:">
        <el-input placeholder="请输入姓名" v-model="search.name" size="medium" class="form-line-item"></el-input>
      </el-form-item>
      <el-form-item label="单位:">
        <el-input
          placeholder="请输入单位名称"
          v-model="search.university"
          size="medium"
          class="form-line-item"
        ></el-input>
      </el-form-item>

      <el-form-item label="搜集学科:">
        <el-input
          placeholder="请输入搜集学科"
          :disabled="!!search.customMajor"
          v-model="search.expertMajor"
          size="medium"
          class="form-line-item"
        ></el-input>
      </el-form-item>
      <el-form-item label="自填学科:">
        <el-input
          placeholder="请输入自填学科"
          :disabled="!!search.expertMajor"
          v-model="search.customMajor"
          size="medium"
          class="form-line-item"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱:">
        <el-input placeholder="请输入邮箱" v-model="search.mail" size="medium" class="form-line-item"></el-input>
      </el-form-item>
      <el-form-item label="手机:">
        <el-input placeholder="请输入手机号" v-model="search.mobile" size="medium" class="form-line-item"></el-input>
      </el-form-item>

      <el-form-item label="银行卡:">
        <el-input
            placeholder="请输入银行卡号"
            v-model="search.bankCardNum"
            size="medium"
            class="form-line-item"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="评阅总数:">
        <el-input placeholder="请输入总数区间：20-30" v-model="search.reviewCount" size="medium" class="form-line-item" ></el-input>
      </el-form-item>
      <el-form-item label="评审意愿:">
        <el-select v-model="search.accept" size="medium" clearable placeholder="请选择评审意愿"
                   class="form-line-item"
                   ref="selectAccept"
                   style="width: 170px"
                   @visible-change="isShowSelectOptions"
        >
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
          <el-option label="C" value="C"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评审质量:">
        <el-select v-model="search.quality" size="medium" clearable placeholder="请选择评审质量"
                   class="form-line-item"
                   ref="selectQuality"
                   style="width: 170px"
                   @visible-change="isShowSelectOptions"
        >
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评审速度:">
        <el-select v-model="search.frequency" size="medium" clearable placeholder="请选择评审速度"
                   class="form-line-item"
                   ref="selectFrequency"
                   style="width: 170px"
                   @visible-change="isShowSelectOptions"
        >
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
          <el-option label="C" value="C"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="严格程度:">
        <el-input placeholder="请输入分数区间：80-90" v-model="search.avgScore" size="medium" class="form-line-item"></el-input>
      </el-form-item>
      <el-form-item label="黑名单:">
        <el-select v-model="search.block" size="medium" clearable placeholder="请选择是否黑名单"
                   class="form-line-item"
                   ref="selectBlock"
                   style="width: 170px"
                   @visible-change="isShowSelectOptions"
        >
          <el-option label="是" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 10px">
        <el-button type="primary" size="mini" @click="batchSearch" icon="el-icon-search">搜索</el-button>
        <el-button type="success" size="mini" @click="resetHandle" icon="el-icon-refresh">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <div>
      <!-- <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;">
        <template #buttons>
          <el-button type="success" size="small" @click="insertHandle()">添加账号</el-button>
        </template>
      </vxe-toolbar>-->
      <!-- :loading="loading" -->
      <!--        <vxe-toolbar>-->
      <!--            <template #buttons>-->
      <!--                <vxe-button @click="saveEvent">保存</vxe-button>-->
      <!--            </template>-->
      <!--        </vxe-toolbar>-->
      <vxe-table
        :header-cell-style="headerCellStyle"
        round
        align="center"
        ref="schoolTable"
        highlight-current-row
        highlight-hover-row
        keep-source
        class="mytable-scrollbar expert-account"
        :keyboard-config="{ isArrow: true }"
        :checkbox-config="{ trigger: 'cell', highlight: true, range: false }"
        :loading="loading"
        :edit-config="{trigger: 'manual', mode: 'row',showStatus: true}"
        @edit-closed="cancelAll"
        :data="paperList"
      >
        <!-- <vxe-column type="seq" title="序号" width="100" :show-overflow="'tooltip'"></vxe-column> -->

        <!-- <vxe-column field="username" title="账号" width="250" :show-overflow="'tooltip'"> -->
          <!--            <template #edit="{ row }">-->
          <!--                <vxe-input v-model="row.username" type="text"></vxe-input>-->
          <!--            </template>-->
        <!-- </vxe-column> -->
        <!-- <vxe-column field="expertName" title="姓名" width="200" :show-overflow="'tooltip'"> -->
          <!--            <template #edit="{ row }">-->
          <!--                <vxe-input v-model="row.expertName" type="text"></vxe-input>-->
          <!--            </template>-->
        <!-- </vxe-column> -->

          <vxe-column type="seq" title="序号" width="3%" :show-overflow="'tooltip'"></vxe-column>
        <!-- <vxe-column field="expertId" title="ID" width="10%" :show-overflow="'tooltip'"></vxe-column> -->
        <vxe-column field="expertId" title="ID" width="6%" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="username" title="账号" width="10%" :show-overflow="'tooltip'">
          <!--            <template #edit="{ row }">-->
          <!--                <vxe-input v-model="row.username" type="text"></vxe-input>-->
          <!--            </template>-->
        </vxe-column>
        <vxe-column field="expertName" title="姓名" width="5%" :show-overflow="'tooltip'">
          <!--            <template #edit="{ row }">-->
          <!--                <vxe-input v-model="row.expertName" type="text"></vxe-input>-->
          <!--            </template>-->
        </vxe-column>
         <vxe-column field="institutionName" title="单位" width="8%" :show-overflow="'tooltip'">
        </vxe-column>
<!--        <vxe-column field="expertMajor" title="搜集学科" width="8%" :show-overflow="'tooltip'">-->
<!--        </vxe-column>-->
        <vxe-column field="customMajor" title="自填学科" width="8%" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column
          field="mail"
          title="邮箱"
          width="12%"
          :show-overflow="'tooltip'"
          :edit-render="{}"
        >
          <template #edit="{ row }">
            <vxe-input v-model="row.mail" type="text"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="mobile" title="手机号" width="8%" :show-overflow="'tooltip'">
          <template #default="{ row }">
            <div>{{ row.mobile ? row.mobile : "" }}</div>
          </template>
          <!--            <template #edit="{ row }">-->
          <!--                <vxe-input v-model="row.mobile" type="number"></vxe-input>-->
          <!--            </template>-->
        </vxe-column>

        <vxe-column type='expand' field="keywords" title="详情" width="5%" :show-overflow="'tooltip'">
          <template #content="{ row}">
            <div style="padding:20px;">
              <el-descriptions border :column='2' :labelStyle="{ 'width': '10%' }">
                <el-descriptions-item label="姓名">{{ row.expertName }}</el-descriptions-item>
                <el-descriptions-item label="单位">{{ row.institutionName }}</el-descriptions-item>
                <el-descriptions-item label="职称">
                  {{ titleMap[row.title] }}
                </el-descriptions-item>
                <el-descriptions-item label="导师类型">{{ typeMap[row.type] }}
                </el-descriptions-item>
                <el-descriptions-item label="邮箱">{{ row.mail }}</el-descriptions-item>
                <el-descriptions-item label="手机号">{{ row.mobile }}</el-descriptions-item>
                <el-descriptions-item label="收集学科">{{ row.subject }}</el-descriptions-item>
                <el-descriptions-item label="专家自填学硕学科">{{ row.customMajor?row.customMajor.split('%').map(item=>item.split(',').join('-')).join('、'):'' }}</el-descriptions-item>
                <el-descriptions-item label="专家自填专硕学科">{{ row.customMajorMaster?row.customMajorMaster.split('%').map(item=>item.split(',').join('-')).join('、'):'' }}</el-descriptions-item>
                <el-descriptions-item label="专家自填本科专业">{{ row.customMajorMaster?row.customMajorUndergraduate.split('%').map(item=>item.split(',').join('-')).join('、'):'' }}</el-descriptions-item>
                <el-descriptions-item label="评审意愿等级">{{ row.accept }}</el-descriptions-item>
                <el-descriptions-item label="评审意愿">{{ row.acceptDetail }}</el-descriptions-item>
                <el-descriptions-item label="评审质量等级">{{ row.quality }}</el-descriptions-item>
                <el-descriptions-item label="评审质量">{{ row.qualityDetail }}</el-descriptions-item>
                <el-descriptions-item label="评审速度等级">{{ row.frequency }}</el-descriptions-item>
                <el-descriptions-item label="评审速度">{{ row.frequencyDetail }}</el-descriptions-item>
                <el-descriptions-item label="评阅总数">{{ row.reviewCount }}</el-descriptions-item>
                <el-descriptions-item label="严格程度">
                  {{ getIntegerPart(row.avgScore) }}
                </el-descriptions-item>
                <el-descriptions-item label="是否可用">{{ row.block==1?'否':'是' }}</el-descriptions-item>
                <!-- <el-descriptions-item label="二级学科">{{row.subMajor}}</el-descriptions-item> -->
                <!-- <el-descriptions-item label="研究方向">{{row.theme}}</el-descriptions-item>
                <el-descriptions-item label="关键字">{{row.keywords}}</el-descriptions-item>
                <el-descriptions-item label="支付方式">{{payMethod[row.defaultPayType].text}}</el-descriptions-item>
                <el-descriptions-item label="账号">{{row.payAccount}}</el-descriptions-item> -->
              </el-descriptions>
            </div>
          </template>
        </vxe-column>


        <vxe-column title="操作" min-width="15%">
          <template #default="{ row }">
            <el-popconfirm title="确定加入黑名单吗？" @confirm="editLevel(4, row.expertId)">
              <el-button
                  :disabled="!row.username"
                  slot="reference"
                  size="mini"
                  type="danger"
                  style="margin-right: 3px"
              >黑名单</el-button>
            </el-popconfirm>

            <el-popconfirm title="确定加入白名单吗？" @confirm="updateBlock(row.expertId)">
              <el-button
                  :disabled="!row.username"
                  slot="reference"
                  size="mini"
                  type="primary"
                  style="margin-right: 3px"
              >白名单</el-button>
            </el-popconfirm>

            <template v-if="$refs.schoolTable.isActiveByRow(row)">
              <!--                  <vxe-button @click="saveRowEvent(row)">保存</vxe-button>-->
              <!--                  <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>-->
              <el-button
                size="mini"
                type="primary"
                style="margin-left: 5px"
                @click="saveRowEvent(row)"
              >保存</el-button>
              <el-button
                size="mini"
                type="info"
                style="margin-left: 5px"
                @click="cancelRowEvent(row)"
              >取消</el-button>
            </template>
            <template v-else>
              <el-popconfirm title="确定重置吗？" @confirm="resetPwdHandle(row.userId)">
                <el-button
                  :disabled="!row.username"
                  slot="reference"
                  size="mini"
                  type="danger"
                  style="margin-right: 3px"
                >重置密码</el-button>
              </el-popconfirm>

<!--              <el-button-->
<!--                size="mini"-->
<!--                type="primary"-->
<!--                style="margin-right: 3px"-->
<!--                @click="editRowEvent(row)"-->
<!--              >修改邮箱</el-button>-->
              <el-button
                  size="mini"
                  type="primary"
                  style="margin-right: 3px"
                  @click="editExpertDetail(row)"
              >修改信息</el-button>
              <!--              <el-button size="mini" type="success" style="margin-left: 5px" @click>修改</el-button>-->
              <el-popconfirm title="确定删除吗？" @confirm="deleteExpert(row.expertId)">
                <el-button
                    :disabled="!row.username"
                    slot="reference"
                    size="mini"
                    type="danger"
                    style="margin-right: 3px"
                    v-show="$store.state.user.info.buttons.indexOf('btn.ExpertAccount.deleteExpert') != -1"
                >删除</el-button>
              </el-popconfirm>
              <el-button type="primary" size="mini" @click="showOperationdetails(row)">操作详情</el-button>

              <el-button type="primary" size="mini" @click="showReviewdetails(row)" style="margin-left: 3px">评阅详情</el-button>

              <el-button
                :disabled="!row.username"
                size="mini"
                type="primary"
                @click="entryTo(row)"
                style="margin-left: 3px"
              >进入</el-button>
            </template>

          </template>

        </vxe-column>

      </vxe-table>
      <el-dialog
          title="修改信息"
          :visible.sync="editExpertDetaildialogVisible"
          width="30%"
      >
        <el-form :model="expertform">
          <el-form-item label="姓名">
            <el-input v-model="expertform.expertName" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="expertform.mail" placeholder="请输入邮箱"></el-input>
          </el-form-item>
<!--          <el-form-item label="职称">-->
<!--            <el-input v-model="expert.title" placeholder="请输入职称"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="导师类型">-->
<!--            <el-input v-model="expert.type" placeholder="请输入导师类型"></el-input>-->
<!--          </el-form-item>-->
          <!-- 职称 下拉框 -->
          <el-form :label-position="'top'">
            <el-form-item label="职称">
              <el-select v-model="expertform.title" placeholder="请选择职称">
                <el-option
                    v-for="item in titleOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <!-- 导师类型 下拉框 -->
          <el-form :label-position="'top'">
          <el-form-item label="导师类型">
            <el-select v-model="expertform.type" placeholder="请选择导师类型">
              <el-option
                  v-for="(label, value) in typeMap"
                  :key="value"
                  :label="label"
                  :value="value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
          <el-form-item label="收集学科">
            <el-input v-model="expertform.subject" placeholder="请输入收集学科"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editExpertDetailhandleSave">确 定</el-button>
        </div>
      </el-dialog>


      <el-dialog
          v-model="operationdetails"
          title="操作详情"
          :visible.sync="operationdetails"
          width="70%"
          style="height: 1000px; max-height: 100%; overflow-y: auto;"
          @close="handleDialogClose"
      >
        <div style="width: 100%; text-align: center;">
        <el-form  :inline="true">
          <el-form-item label="论文题目:">
            <el-input placeholder="请输入论文题目" style="width:300px;" v-model="operationSearch.title" size='medium'
                      class="form-line-item">
            </el-input>
          </el-form-item>
          <el-form-item label="操作日期:">
            <el-date-picker v-model="operationSearch.time" size='medium' type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="操作:">
            <el-select v-model="operationSearch.state" size="medium" clearable placeholder="请选择状态"
                       class="form-line-item"
                       ref="selectState"
                       @visible-change="isShowSelectOptions"
            >
              <el-option label="过期" :value="4"></el-option>
              <el-option label="拒审" :value="3"></el-option>
<!--              <el-option label="待送审" :value="0"></el-option>-->
              <el-option label="邀审" :value="1"></el-option>
              <el-option label="接审" :value="2"></el-option>
              <el-option label="提交" :value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" label-width="60px">
            <el-button type="primary" size="mini" icon="el-icon-search" @click="getOperationDetail">搜索</el-button>
            <el-button type="success" size="mini" @click="resetOperationHandle" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-form>

        <el-table :data="operationlist" v-loading="operationLoading" element-loading-background = "rgba(0, 0, 0, 0.5)"
                  element-loading-text = "数据正在加载中" element-loading-spinner = "el-icon-loading" :max-height="500"
                  style="display: inline-block; width: auto; margin: 0 auto;">
          <el-table-column property="num" label="序号" width="80" type="index"></el-table-column>
          <el-table-column property="institution" label="单位" width="260" show-overflow-tooltip></el-table-column>
          <el-table-column property="batch" label="批次" width="180"  show-overflow-tooltip></el-table-column>
          <el-table-column property="staff" label="负责人" width="80"></el-table-column>
          <el-table-column property="title" label="论文题目" width="360"  show-overflow-tooltip></el-table-column>
          <el-table-column property="operation" label="操作" width="120"></el-table-column>
          <el-table-column property="operationTime" label="操作时间" width="180" sortable></el-table-column>
        </el-table>
        </div>
<!--        <template #footer>-->
<!--          <el-button @click="operationdetails = false">关闭</el-button>-->
<!--        </template>-->
      </el-dialog>

      <el-dialog
          v-model="reviewdetails"
          title="评阅详情"
          :visible.sync="reviewdetails"
          width="70%"
          style="height: 1000px; max-height: 100%; overflow-y: auto;"
          @close="handleDialogClose_2"
      >
        <div style="width: 100%; text-align: center;">
          <el-form  :inline="true">
            <el-form-item label="论文题目:">
              <el-input placeholder="请输入论文题目" style="width:300px;" v-model="reviewSearch.title" size='medium'
                        class="form-line-item">
              </el-input>
            </el-form-item>
            <el-form-item label="" label-width="60px">
              <el-button type="primary" size="mini" icon="el-icon-search" @click="getReviewDetail">搜索</el-button>
              <el-button type="success" size="mini" @click="resetReviewHandle" icon="el-icon-refresh">重置</el-button>
            </el-form-item>
          </el-form>

          <el-table :data="reviewlist" v-loading="reviewLoading" element-loading-background = "rgba(0, 0, 0, 0.5)"
                    element-loading-text = "数据正在加载中" element-loading-spinner = "el-icon-loading" :max-height="500"
                    style="display: inline-block; width: auto; margin: 0 auto;">
            <el-table-column property="num" label="序号" width="80" type="index"></el-table-column>
<!--            <el-table-column property="institution" label="单位" width="260" show-overflow-tooltip></el-table-column>-->
<!--            <el-table-column property="batch" label="批次" width="180"  show-overflow-tooltip></el-table-column>-->
<!--            <el-table-column property="staff" label="负责人" width="80"></el-table-column>-->
            <el-table-column property="title" label="论文题目" width="360"  show-overflow-tooltip></el-table-column>
            <el-table-column property="type" label="论文类型" width="200"  show-overflow-tooltip></el-table-column>
            <el-table-column property="institutionId" label="送审单位" width="200"  show-overflow-tooltip></el-table-column>
            <el-table-column property="submitTime" label="评审日期" width="200"  show-overflow-tooltip sortable>
            </el-table-column>
            <el-table-column property="reviewContent" label="分数" width="150"  show-overflow-tooltip></el-table-column>
            <el-table-column property="isPass" label="评审结果" width="150"  show-overflow-tooltip>
              <template #default="{ row }">
                    <span :style="{ color: row.isPass === 1 ? 'green' : '#bf291b' }">
                      {{ row.isPass === 1 ? '通过' : '未通过' }}
                    </span>
              </template>
            </el-table-column>

<!--            <el-table-column property="operation" label="操作" width="120"></el-table-column>-->
<!--            <el-table-column property="operationTime" label="操作时间" width="180" sortable></el-table-column>-->
          </el-table>
        </div>
      </el-dialog>



      <!-- <vxe-pager perfect align='center' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
        :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
        @page-change="handlePageChange">
        <template #left>
          <vxe-button size="small" @click="firstPage">首页
          </vxe-button>
        </template>
      </vxe-pager>-->
      <vxe-pager
        perfect
        align="right"
        :current-page.sync="page.currentPage"
        :page-size.sync="page.pageSize"
        :total="page.total"
        :page-sizes="[
          10,
          20,
          100,
          1000,
          { label: '全量数据', value: page.total },
        ]"
        :layouts="[
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'Sizes',
          'Total',
]"
        @page-change="handlePageChange"
      >
        <template #left>
          <vxe-button size="small" @click="firstPage">首页</vxe-button>
        </template>
      </vxe-pager>
    </div>
  </div>
</template>

<script>
import base from "@/api/http/baseUrl";
export default {
  name: "ExpertAccount",
  data() {
    return {
      loading: false,
      search: {
        name: "",
        mail: "",
        mobile: "",
        university: "",
        //ll修改
        customMajor: "",
        expertMajor: "",
        bankCardNum: "",
        reviewCount: "",
        accept: "",
        quality: "",
        frequency: "",
        avgScore: "",
        block:""
      },
      //论文列表
      paperList: [],
      //分页
      page: {
        currentPage: 1,
        pageSize: 10,
        pageCount: 40, //自己用
        pageSizes: [1, 5, 10, 15, 20],
        total: 0
      },
      isMailModify: false,
      titleMap: {
        '1': '正高级',
        '0': '副高级',
        '2': '讲师',
        '3': '其他'
      },
      titleOption:[{
        value:'1',
        label:'正高级'
      },{
        value:'0',
        label:'副高级'
      },{
        value:'2',
        label:'讲师'
      },{
        value:'3',
        label:'其他'
      }],
      typeMap:{
        '0': '硕导',
        '1': '博导',
        '2': '其他'
      },
      operationdetails:false,
      operationSearch: {
        title: '',
        time: ['', ''],
        state: '',
      },
      operationData: {},
      operationlist: [],
      operationLoading: false,
      editExpertDetaildialogVisible:false,
      expertform: {
        expertName: '',
        expertId: '',
        mail: '',
        title: '',
        type: '',
        subject: ''
      },
      reviewdetails:false,
      reviewData: {},
      reviewSearch: {
        title: '',
      },
      reviewLoading: false,
      reviewlist: [],
    };
  },
  created() {
    window.addEventListener("keydown", this.handleKeyPress);
    this.getPaperList();
  },
  methods: {
    editExpertDetail(row) {
      // 初始化表单数据
      this.expertform.expertId = row.expertId;
      this.expertform.expertName = row.expertName;
      this.expertform.mail = row.mail;
      this.expertform.title = row.title;
      this.expertform.type = row.type;
      // this.expertform.type = this.typeMap[row.type] || row.type; // 如果没有匹配的映射，则显示原值
      // this.expertform.title = this.titleMap[row.title] || row.title; // 如果没有匹配的映射，则显示原值

      this.expertform.subject = row.subject;
      // 显示弹窗
      this.editExpertDetaildialogVisible = true;
    },
    editExpertDetailhandleSave() {
      // 获取中文文本
      // let titleText = this.expertform.title;  // 中文文本
      // let typeText = this.expertform.type;    // 中文文本
      // console.log("1111111111111",this.titleText)
      // // 反向映射：根据中文文本找到对应的数字
      // let title = Object.keys(this.titleMap).find(key => this.titleMap[key] === titleText);
      // let type = Object.keys(this.typeMap).find(key => this.typeMap[key] === typeText);
      let params = {
        expertId: this.expertform.expertId,  // 获取当前行中的 expertId
        expertName: this.expertform.expertName,  // 从表单中获取 expertName
        mail: this.expertform.mail,  // 从表单中获取 mail
        title: this.expertform.title,
        type: this.expertform.type,
        subject: this.expertform.subject  // 从表单中获取 subject
      };
      console.log("1111111111111",params)
      console.log("1111111111111",params)
      // 调用 API 接口更新专家信息
      this.$api.basicInfo.setExpertDetail(params)
          .then(res => {
            if (res.data.code == 200) {
              // 成功处理
              this.$message.success("修改成功！");
              this.editExpertDetaildialogVisible = false;  // 关闭弹窗
              this.batchSearch();  // 刷新数据（或者你可以自己定义刷新方法）
            }
          })
          .catch(err => {
            console.log(err);
            this.$message.warning("服务器维护!");
          });
      this.dialogVisible = false;
    },
    // 是否显示下拉框
    isShowSelectOptions(isShowSelectOptions){
      if(!isShowSelectOptions) this.$refs.selectAccept.blur();
      if(!isShowSelectOptions) this.$refs.selectQuality.blur();
      if(!isShowSelectOptions) this.$refs.selectFrequency.blur();
      if(!isShowSelectOptions) this.$refs.selectBlock.blur();
    },
    handleKeyPress(event) {
      if (event.keyCode === 13 && !this.isMailModify) {
        this.batchSearch();
      }
    },
    editLevel(level, expertId) {
      let params = {
        expertId: expertId,
        priority: level,
        blockingReason: ''
      };
      this.$api.basicInfo.setExpertPriority(params)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("修改成功！");
            this.batchSearch();
          }
        }).catch(err => {
          console.log(err)
          this.$message.warning("服务器维护!");
        })
    },
    updateBlock(expertId) {
      let params = {
        expertId: expertId
      };
      this.$api.basicInfo.setExpertBlock(params)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("修改成功！");
              this.batchSearch();
            }
          }).catch(err => {
        this.$message.warning("服务器维护!");
      })
    },

    deleteExpert(expertId) {
      let params = {
        expertId: expertId
      };
      this.$api.basicInfo.deleteExpert(params)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除成功！");
              this.batchSearch();
            }
          }).catch(err => {
        this.$message.warning("服务器维护!");
      })
    },

    getIntegerPart(score) {
      // 检查score是否为null或undefined
      if (score === null || score === undefined) {
        return ''; // 或者返回你想要的默认值，比如'0'
      }
      // 使用正则表达式匹配整数部分
      const match = score.toString().match(/^-?\d+/);
      // 如果匹配到数字，则返回该数字，否则返回空字符串
      return match ? match[0] : '';
    },

    //获取论文列表
    getPaperList() {
      this.loading = true;
      let search = this.search;
      // let param = new URLSearchParams();
      // param.append("name", search.name);
      // param.append("mail", search.mail);
      // param.append('mobile', search.mail);
      // param.append('institution', search.university);

      //源代码
      // let param = {
      //   expertName: search.name,
      //   expertInstitution: search.university,
      //   mail: search.mail,
      //   mobile: search.mobile,
      //   expertMajor: "",
      //   username: "",
      //   pageNum: this.page.currentPage,
      //   pageSize: this.page.pageSize
      // };
      // this.$api.account.postExpertAccounts(param).then(res => {
      //   this.paperList = res.data.data;
      //   this.page.total = res.data.count ? res.data.count : 0;
      //   this.loading = false;
      // });
      //ll修改
      let param = {
        expertName: search.name,
        expertInstitution: search.university,
        mail: search.mail,
        mobile: search.mobile,
        // customMajor: "",
        // expertMajor: "",
        // bankCardNum: ""
        // expertMajor: search.expertMajor,
        customMajor: search.customMajor,
        bankCardNum:search.bankCardNum,
        reviewCount:search.reviewCount,
        accept: search.accept,
        quality:search.quality,
        frequency:search.frequency,
        avgScore:search.avgScore,
        block:search.block,
        username: "",
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize
      };
      this.$api.account.postExpertAccounts(param).then(res => {
        this.paperList = res.data.data;
        this.page.total = res.data.count ? res.data.count : 0;
        this.loading = false;
      });
    },

    showOperationdetails(row){
      this.operationdetails = true;
      // this.operationSearch = {};
      this.operationData = row;
      console.log("操作", this.operationData)
      this.getOperationDetail();
    },
    //获取操作详情
    getOperationDetail(){
      // let param = new URLSearchParams();
      console.log("time111111:", this.operationSearch.time);
      if(this.operationSearch.time == null){
        this.operationSearch.time = ['','']
      }
      console.log("params111111:", this.operationData.expertId);

      let param = {
        expertId: this.operationData.expertId,
        title: this.operationSearch.title,
        state: this.operationSearch.state,
        startTime: this.operationSearch.time[0] ? this.dateFormat(this.operationSearch.time[0]) : '',
        endTime: this.operationSearch.time[1] ? this.dateFormat(this.operationSearch.time[1]) : ''
      };
      this.operationLoading = true;
      this.$api.account.getOperationDetail(param, {
        headers: {
          'Content-Type': 'application/json' // 修改为 'application/json'
        }
        })
          .then(res => {
            console.log(111111111);
            console.log(res);
            if (res.data.code == 200) {
              this.$message.success("查询成功")
              this.operationlist = res.data.data;
              this.operationLoading = false;
            }
          }).catch(err => {
        this.$message.warning("服务器维护");
        console.log(err);
        this.operationLoading = false;
      })
    },
    handleDialogClose() {
      this.operationData = {}; // 清空数据
      this.resetOperationHandle();
    },
    dateFormat: function (time) {
      var datetime = new Date(time);
      // datetime.setTime(time);
      var year = datetime.getFullYear();
      var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
          1;
      var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      return year + "-" + month + "-" + date;
    },
    //搜索
    batchSearch() {
      if (
        //ll修改
        this.search.expertName == "" &&
        this.search.expertMajor == "" &&
        this.search.customMajor == "" &&
        this.search.grade == "" &&
        this.search.type == "" &&
        this.search.title == "" &&
        this.search.honors == "" &&
        this.search.mail == "" &&
        this.search.mobile == "" &&
        this.search.expertInstitution == "" &&
        this.search.bankCardNum == "" &&
        this.search.reviewCount == "" &&
        this.search.accept == "" &&
        this.search.quality == "" &&
        this.search.frequency == "" &&
        this.search.avgScore == "" &&
        this.search.block == ""
      ) {
        return;
      }
      this.getPaperList();
      //源代码
      // if (!this.search.name && !this.search.mail && !this.search.mobile) {
      //   this.$message.warning("至少提供姓名、邮箱、手机号其中之一");
      //   return;
      // }
      // this.getPaperList(this.search);
    },
    // 重置密码
    resetPwdHandle(userId) {
      if (!userId) return;

      let params = {
        userId: userId
      };

      this.$api.user.resetPwd(params).then(res => {
        if (res.data.code == 200) {
          this.$message.success("重置成功，密码为888888");
        } else {
          this.$message.warning("重置失败");
        }
      });
    },

    resetHandle() {
      this.search = {
        name: "",
        mail: "",
        mobile: "",
        university: ""
      };
    },
    resetOperationHandle() {
      this.operationSearch = {
        title: "",
        time: "",
        state: "",
      };
    },
    // 进入院校端
    entryTo(row) {
      window.open(`${base.exprtUrl}#/explanation?token=${row.url}`);
    },
    //重置密码
    // resetPwdHandle(id) {
    //   let param = {
    //     id: id,
    //     type: 1
    //   };
    //   this.$axios.post('/user/reset_password', param)
    //     .then(res => {
    //       if (res.data.data == 1) {
    //         this.$message.success('重置成功！');
    //       } else {
    //         this.$message.warning('重置失败！');
    //       }
    //     }).catch(err => {
    //       console.log('服务器维护！');
    //     })
    // },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background-color:#e0e0e0f1";
    },
    //分页操作
    handlePageChange({ currentPage, pageSize }) {
      this.page.currentPage = currentPage;
      this.page.pageSize = pageSize;
      this.getPaperList();
    },
    firstPage() {
      this.page.currentPage = 1;
      this.getPaperList();
    },
    endPage() {
      this.page.currentPage =
        parseInt(this.page.total / this.page.pageSize) +
        (this.page.total % this.page.pageSize == 0 ? 0 : 1);
      this.getPaperList();
    },
    saveEvent() {
      const $table = this.$refs.schoolTable;
      const {
        insertRecords,
        removeRecords,
        updateRecords
      } = $table.getRecordset();
      let updateExpertList = updateRecords;
      // $table.clearActived()
      console.log(updateExpertList);
    },
    editRowEvent(row) {
      const $table = this.$refs.schoolTable;
      $table.setEditRow(row);
      this.isMailModify = true;
    },
    saveRowEvent() {
      const $table = this.$refs.schoolTable;
      const {
        insertRecords,
        removeRecords,
        updateRecords
      } = $table.getRecordset();
      console.log(updateRecords);
      if (updateRecords.length <= 0) {
        this.$message.warning("数据未改动！");

        return;
      }
      let updateExpert = updateRecords[0];
      let params = {
        expertId: updateExpert.expertId,
        expertMail: updateExpert.mail
      };
      this.loading = true;
      console.log(params);
      this.$api.account
        .updateExpertInfo(params)
        .then(res => {
          // console.log(res);
          // res.data.code=999;
          if (res.data.code == 200) {
            this.batchSearch();
            this.loading = false;
          } else {
            this.$message.warning(res.data.msg);
            this.loading = false;
          }
        })
        .catch(e => {
          this.$message.warning("修改失败，系统维护！");
        });
      this.isMailModify = false;
      this.batchSearch();
    },
    cancelAll({ row, column }) {
      console.log(row);
      const $table = this.$refs.schoolTable;
      const field = column.property;
      // $table.reloadRow(row, null, field);
      $table.revertData(row);
      this.isMailModify = false;
    },
    cancelRowEvent(row) {
      const $table = this.$refs.schoolTable;
      $table.clearEdit().then(() => {
        // 还原行数据
        $table.revertData(row);
      });
      this.isMailModify = false;
    },
    showReviewdetails(row){
      this.reviewdetails = true;
      // this.operationSearch = {};
      this.reviewData = row;
      // console.log("操作", this.operationData)
      this.getReviewDetail();
    },
    //获取评阅详情
    getReviewDetail(){
      // let param = new URLSearchParams();
      let param = {
        expertId: this.reviewData.expertId,
        title: this.reviewSearch.title,
      };
      this.reviewLoading = true;
      this.$api.account.getReviewDetail(param, {
        headers: {
          'Content-Type': 'application/json' // 修改为 'application/json'
        }
      })
          .then(res => {
            // console.log(111111111);
            // console.log(res);
            if (res.data.code == 200) {
              this.$message.success("查询成功")
              this.reviewlist = res.data.data;
              this.reviewLoading = false;
            }
          }).catch(err => {
        this.$message.warning("服务器维护");
        console.log(err);
        this.reviewLoading = false;
      })
    },
    handleDialogClose_2() {
      this.reviewData = {}; // 清空数据
      this.resetReviewHandle();
    },
    resetReviewHandle() {
      this.reviewSearch = {
        title: "",
      };
    },
  },
  components: {}
};
</script>

<style scoped></style>

<style>
.expert-account .vxe-table--body-wrapper {
  height: calc(100vh - 310px);
}
</style>
